import { isLocalStorageEnabled } from 'Lib/is-local-storage-enabled/is-local-storage-enabled';
import { speed } from 'Lib/speed/speed';
import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';

const $buyNow = $('.buy-now');
const cTabsLinkActive = 'tabs__link_active';
const sTabsContainer = '.tabs__container';
const cTabsContainer = 'tabs__container_active';
const $buyNowBasket = $('.buy-now_basket'); // Для страницы Корзины: там не нужно выводить книги, которые добавлены уже в корзину

function changeClasses() {
    $buyNow.find(sTabsContainer).removeClass(cTabsContainer);
    $buyNow.find('.tabs__link').removeClass(cTabsLinkActive);

    if (getW() < eksmoBP.smMax) {
        $buyNow.find(sTabsContainer).addClass(cTabsContainer);
        $('.buy-now__tabs-container-you-watched').addClass('buy-now__tabs-container-you-watched-mobile');
    } else {
        $('.buy-now__link_you-watched').css('display', 'block');
    }
}

if (isLocalStorageEnabled) {
    const booksViewed = JSON.parse(localStorage.getItem('booksViewed'));

    if (booksViewed) {
        if ($buyNowBasket.length) {
            const timer = setInterval(() => {
                if (JSON.parse(localStorage.getItem('booksViewedNoBasket'))?.length) {
                    const booksViewedNoBasket = JSON.parse(localStorage.getItem('booksViewedNoBasket'));

                    clearInterval(timer);
                    $buyNow.find('.books-slider').last().attr('data-post-load', booksViewedNoBasket.join());
                    changeClasses();
                    localStorage.removeItem('booksViewedNoBasket');
                }
            }, speed * 4);
        } else {
            changeClasses();
            $('.buy-now__tabs-container-you-watched').find('.books-slider').attr('data-post-load', booksViewed.join());
        }

        $('body').trigger('init.tabs.eksmo');
    } else if (getW() > eksmoBP.mdMin) {
        setTimeout(() => {
            $('.buy-now__link_buy-now').removeClass(`${cTabsLinkActive} tabs__link`).addClass('buy-now__link_buy-now-not-you-watched');
        }, speed * 3);
    }
}