import { onScreen } from 'Lib/on-screen/on-screen';
import { eventSender } from 'Lib/event-sender/event-sender';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const $body = $('body');
const sPromo = '.promo';

function getId($thisPromo) {
    let id = $thisPromo.attr('href');
    const dataId = $thisPromo.attr('data-promo-id');

    if (isNoEmptyString(dataId)) {
        id = dataId;
    }

    return id;
}

onScreen($(sPromo), ($promo) => {
    const objInfo = {
        'name': $promo.attr('data-ga4-promo-name'),
        'id': $promo.attr('data-ga4-promo-id'),
        'location_id': $promo.attr('data-ga4-promo-location'),
    };

    if ($promo.attr('data-promo-index')) {
        objInfo.creative_slot = $promo.attr('data-promo-index');
    }

    eventSender.promoImpressionsGA4(objInfo);
}, 0, 0, 0.8);

$body.on('click', sPromo, function (e) {
    const $thisPromo = $(this);
    const objInfo = {
        'name': $thisPromo.attr('data-ga4-promo-name'),
        'id': $thisPromo.attr('data-ga4-promo-id'),
        'location_id': $thisPromo.attr('data-ga4-promo-location'),
    };

    if ($thisPromo.attr('data-promo-index')) {
        objInfo.creative_slot = $thisPromo.attr('data-promo-index');
    }

    if ($thisPromo.hasClass('promo_link-inner') && e.target.tagName === 'A') {
        eventSender.promoClickGA4(objInfo);
    } else if (!$thisPromo.hasClass('promo_link-inner')) {
        eventSender.promoClickGA4(objInfo);
    }
});